import { useMutation, useService, useTranslation, useState } from '@hooks';
import { validateUserSignInAuthorization } from '@utils';
import { translateOktaUnsuitableResponse } from '@ui-modules/auth/utils/translateOktaUnsuitableResponse';
import type { IdxTransaction } from '@okta/okta-auth-js';
import type { TAuthCredentials } from '@typings';
import type { OktaError } from '../../../services/implementations/OktaService';
import { OktaAuthenticatorEnrollRequiredError } from '../../../services/implementations/OktaService/errors/OktaAuthenticatorEnrollRequiredError';
import type { IModalProps } from '../../../ui-kit';

const CLOSED_MODAL_PROPS: IModalProps = { visible: false };

export const useOktaSignInMutation = ({ onVerifyRequired }: { onVerifyRequired: () => void }) => {
	const { t } = useTranslation();
	const okta = useService('OktaService');
	const api = useService('ApiService');
	const crypto = useService('CryptoService');
	const [modalProps, setModalProps] = useState<IModalProps>(CLOSED_MODAL_PROPS);

	const signInMutation = useMutation<IdxTransaction, OktaError | Error, TAuthCredentials>(
		async (credentials) => {
			const loginOrEmail = credentials.login;
			const encryptedLoginOrEmail = await crypto.encryptAes256Cbc(loginOrEmail);
			const { login: encryptedLogin, ...userAuthorizationFlags } =
				await api.auth.exchangeLoginV2(encryptedLoginOrEmail);
			validateUserSignInAuthorization(t, loginOrEmail)(userAuthorizationFlags);
			const decryptedLogin = await crypto.decryptAes256Cbc(encryptedLogin);

			return await okta.signIn({ ...credentials, login: decryptedLogin });
		},
		{
			onSuccess(idxTransaction) {
				if (idxTransaction.status === 'PENDING') onVerifyRequired();
			},
			onError(error) {
				if (error instanceof OktaAuthenticatorEnrollRequiredError) {
					const errorMessage = error.getMessage(t);
					const modal: IModalProps = {
						visible: true,
						title: errorMessage.title,
						modalContent: errorMessage.subtitle,
						confirmTitle: t('Proceed'),
						onConfirm: () => {
							setModalProps(CLOSED_MODAL_PROPS);
							window.location.href = okta.getOktaIssuerUrl();
						},
						cancelTitle: t('Cancel'),
						onCancel: () => setModalProps(CLOSED_MODAL_PROPS),
					};
					setModalProps(modal);
				}
			},
		},
	);
	const submitError = translateOktaUnsuitableResponse(t)(signInMutation.error);

	return { ...signInMutation, submitError, modalProps };
};
